import * as RadixAccordion from '@radix-ui/react-accordion';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';

import { trackInteraction } from '@/utils/analytics';
import window from '@/utils/window';

import styles from './Accordion.module.scss';

/**
 * @param {any | any[]} x accordion ids, it can be a single value or a array of values
 * @returns {string[]} returns a normalized array of accordion ids
 */
const normalizeOpenAccordionIds = (x) => {
    return [x]
        .flatMap((v) => v)
        .filter((v) => v != null)
        .map((v) => `${v}`);
};

/**
 * Scroll to the element if it's not in the viewport
 * @param {Element} el - The element to scroll to
 * @param {Number} percentageFromViewportTop - The percentage of the elements top from the viewport top to scroll to
 * @param {Number} delay - The delay in milliseconds before scrolling
 */
const scrollIfNeeded = (el, percentageFromViewportTop = 0.2, delay = 300) => {
    const { innerHeight } = window;
    const { top: elTop } = el.getBoundingClientRect();
    const threshold = innerHeight * percentageFromViewportTop;
    // If the element is beneath the viewport threshold or not in the viewport, scroll to it
    if (elTop > threshold || elTop < 0) {
        setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: 'start' }), delay);
    }
};

const singleScrollIfNeeded = (el, percentageFromViewportTop = 0.2, delay = 75) => {
    const { innerHeight } = window;
    const { top: elTop } = el.getBoundingClientRect();
    const threshold = innerHeight * percentageFromViewportTop;
    // If the element is beneath the viewport threshold or not in the viewport, scroll to it
    if (elTop > threshold || elTop < 0) {
        setTimeout(() => {
            const { top: scrollTop } = el.getBoundingClientRect();
            const scrollTo = scrollTop + window.scrollY;

            window.scrollTo({ top: scrollTo });
        }, delay);
    }
};

/**
 * The Accordion atom is used to render a list of collapsible items. It can have disabled accordions, or an accordion that is open by default.
 */
export const Accordion = ({
    type = 'single',
    openAccordion,
    accordionItems,
    className,
    placeholderComponent,
    analytics,
    noScrollLogic,
    ...props
}) => {
    const accordionRef = useRef(null);
    const openAccordionRef = useRef([]);

    const [activePanels, setActivePanels] = React.useState(() => {
        return normalizeOpenAccordionIds(openAccordion);
    });
    React.useEffect(() => {
        setActivePanels(normalizeOpenAccordionIds(openAccordion));
    }, [openAccordion]);

    // Callback to handle the change of the open accordion and scroll to the new open accordion
    const onChange = useCallback(
        (openAccordionIds) => {
            setActivePanels(normalizeOpenAccordionIds(openAccordionIds));

            if (!openAccordionIds || openAccordionIds.length === 0) {
                openAccordionRef.current = openAccordionIds;
                return;
            }

            if (
                Array.isArray(openAccordionIds) &&
                openAccordionIds.length > openAccordionRef.current.length
            ) {
                const newOpenId = openAccordionIds[openAccordionIds.length - 1];
                const newOpenEl = document.getElementById(newOpenId);

                if (newOpenEl && !noScrollLogic) {
                    scrollIfNeeded(newOpenEl);
                }
            } else if (!Array.isArray(openAccordionIds)) {
                const openAccordion = document.getElementById(openAccordionIds);

                if (openAccordion & !noScrollLogic) {
                    singleScrollIfNeeded(openAccordion);
                }
            }
            openAccordionRef.current = openAccordionIds;
        },
        [noScrollLogic],
    );

    const handleClickEvent = React.useCallback(
        (event, itemTitle) => {
            if (analytics) {
                const isOpen = event.target.getAttribute('data-state') === 'open';
                trackInteraction({
                    componentName: 'Accordion List',
                    actionLabel: itemTitle,
                    interactionType: isOpen ? 'close' : 'expand',
                    selector: 'accordion',
                    ...analytics,
                });
            }
        },
        [analytics],
    );
    return (
        <>
            <RadixAccordion.Root
                {...props}
                ref={accordionRef}
                className={classnames(
                    styles.accordion,
                    type === 'multiple' && styles[`accordion--multiple`],
                    className,
                )}
                type={type}
                value={type === 'single' ? activePanels[0] : activePanels}
                onValueChange={onChange}
                collapsible={type === 'single' ? 'true' : 'false'}
            >
                {accordionItems?.map((item, index) => {
                    return (
                        <RadixAccordion.Item
                            key={index}
                            id={`${item.id}`}
                            value={item.id}
                            className={styles[`accordion__item`]}
                        >
                            <RadixAccordion.Header asChild>
                                <RadixAccordion.Trigger
                                    className={classnames(
                                        'type-t1',
                                        styles.accordion__trigger,
                                        item.disabled && styles.disabled,
                                    )}
                                    disabled={item.disabled}
                                    aria-disabled={item.disabled}
                                    onClick={(event) => {
                                        handleClickEvent(event, item.title);
                                    }}
                                    data-trigger={
                                        analytics ? analytics?.selector || 'accordion' : undefined
                                    }
                                >
                                    {item.title}
                                    <div className={styles[`accordion__trigger-end`]}>
                                        {item.titleIndicator && item.titleIndicator}
                                        <span className={styles[`accordion__trigger-icon`]} />
                                    </div>
                                </RadixAccordion.Trigger>
                            </RadixAccordion.Header>

                            <RadixAccordion.Content
                                className={classnames(
                                    styles.accordion__content,
                                    'accordion__panel',
                                )}
                                // necessary only for editor mode, radix accordion remove the element from the DOM
                                // AEM accordion need the child rendered to handle the select panel function
                                forceMount={!!placeholderComponent}
                            >
                                <div
                                    className={classnames(
                                        styles[`accordion__content-inner`],
                                        'accordion__panel-inner',
                                        !!placeholderComponent &&
                                            !activePanels.includes(item.id) &&
                                            styles['accordion__panel-inner--is-editor'],
                                    )}
                                >
                                    {item.children}
                                </div>
                            </RadixAccordion.Content>
                        </RadixAccordion.Item>
                    );
                })}
            </RadixAccordion.Root>
            {placeholderComponent}
        </>
    );
};

Accordion.propTypes = {
    /**
     * The type of accordion to render. Can be 'single' or 'multiple'
     */
    type: PropTypes.oneOf(['single', 'multiple']),
    /**
     * The id of the accordion item that should be open by default
     */
    openAccordion: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    /**
     * The accordion items to render
     */
    accordionItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            children: PropTypes.node.isRequired,
            disabled: PropTypes.bool,
        }),
    ).isRequired,
    /**
     * Additional classes to apply to the accordion
     */
    className: PropTypes.string,
};

export default Accordion;
