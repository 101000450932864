import PropTypes from 'prop-types';
import React from 'react';

const defaultValue = [];

const ContentTreeContext = React.createContext(defaultValue);
export const ContentTreeProvider = (props) => {
    const { children, name } = props;

    const parentContext = React.useContext(ContentTreeContext);
    const contentTree = [...parentContext, name].filter(Boolean);

    return (
        <ContentTreeContext.Provider value={contentTree}>{children}</ContentTreeContext.Provider>
    );
};

ContentTreeProvider.propTypes = {
    /**
     * Name of the content tree level
     */
    name: PropTypes.string,
};

export const useContentTreeContext = () => {
    return React.useContext(ContentTreeContext);
};

export const useIsChild = (of) => {
    const context = useContentTreeContext();

    if (context === null || context?.length === 0) {
        return false;
    }

    if (!of && context.length > 0) {
        return true;
    }

    return context.includes(of);
};
