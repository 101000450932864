import PropTypes from 'prop-types';

import { Accordion } from '@/design-system/atoms/Accordion';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Text } from '@/design-system/atoms/Text';
import { Title } from '@/design-system/atoms/Title';
import { ContentTreeProvider } from '@/design-system/contexts/ContentTreeContext/ContentTreeContext';

import styles from './AccordionList.module.scss';

/**
 *
 * The Accordion List component is a molecule that displays a list of accordions with an optional media component, title and subtitle.
 */
const AccordionList = ({
    title,
    subtitle,
    type = 'single',
    openAccordion,
    accordionItems,
    media,
    accordionListImage,
    placeholderComponent,
    htmlId,
}) => {
    const accordionCols = media ? { sm: 4, md: 6, lg: 8 } : { sm: 4, md: 6, lg: 12 };

    return (
        <ContentTreeProvider name="accordion-list">
            <ContentContainer className={styles[`accordion-list`]} id={htmlId}>
                <Grid className={styles[`accordion-list__grid`]} col={{ sm: 4, md: 6, lg: 12 }}>
                    <GridColumn
                        className={styles[`accordion-list__content`]}
                        colSpan={{ sm: 4, md: 6, lg: 8 }}
                    >
                        {title && (
                            <Title tag={Title.TAG.H2} variant={Title.VARIANT.H3} content={title} />
                        )}
                        {subtitle && (
                            <Text tag={Text.TAG.P} variant={Text.VARIANT.T2} content={subtitle} />
                        )}
                    </GridColumn>
                </Grid>
                <Grid
                    className={styles[`accordion-list__grid`]}
                    col={{ sm: 4, md: 6, lg: 12 }}
                    verticalAlignment="bottom"
                >
                    <GridColumn
                        className={styles[`accordion-list__accordions`]}
                        colSpan={accordionCols}
                    >
                        {accordionItems && (
                            <Accordion
                                type={type}
                                openAccordion={openAccordion}
                                accordionItems={accordionItems}
                                placeholderComponent={placeholderComponent}
                                analytics={{
                                    componentName: 'Accordion List',
                                    componentTitle: title,
                                }}
                            />
                        )}
                    </GridColumn>
                    {media !== null && (
                        <GridColumn
                            className={styles[`accordion-list__media`]}
                            colSpan={{ sm: 4, md: 6, lg: 4 }}
                        >
                            {accordionListImage}
                        </GridColumn>
                    )}
                </Grid>
            </ContentContainer>
        </ContentTreeProvider>
    );
};

AccordionList.propTypes = {
    /**
     * Title of the accordion list
     */
    title: PropTypes.string,
    /**
     * Subtitle of the accordion list
     */
    subtitle: PropTypes.string,
    /**
     * The type of accordion to render. Can be 'single' or 'multiple'
     */
    type: Accordion.propTypes.type,
    /**
     * The id of the accordion item that should be open by default
     */
    openAccordion: Accordion.propTypes.openAccordion,
    /**
     * Array of accordion items
     */
    accordionItems: Accordion.propTypes.accordionItems,
    /**
     * Media type to display
     */
    media: PropTypes.oneOf(['image', 'illustration']),

    /**
     * Accordion List Image item to display
     */
    accordionListImage: PropTypes.node,
    /**
     * Unique component ID
     * */
    htmlId: PropTypes.string,
};
export default AccordionList;
